<div class="college-wrapper">
  <div class="intro">
    <h2>{{'main-page.college.name' | translate}}</h2>
  </div>
  <div class="cont">
    <div class="content">
      <div class="text-imgs">
        <div class="big-text" [innerHTML]="'main-page.college.big-text'| translate"></div>
        <div class="images">
          <a href="http://xn----7sbgfh2alwzdhpc0c.xn--90ais/organization/38327/org-page" target="_blank"><img src="assets/banner-slides/qrkod.png" alt="" class="img-fluid"></a>
        
        </div>
       



      </div>
      <div class="custom-card">
        <div class="card-thumb">
          <a href="https://president.gov.by/ru/gosudarstvo/simvolika" target="_blank"><img src="assets/banner-slides/gerb-flag.png" alt="" class="img-fluid"></a>
          <a href="https://president.gov.by/ru/events/2025-god-ob-avlen-godom-blagoustrojstva" target="_blank"><img src="assets/banner-slides/logo_2025.jpg" alt="" class="img-fluid"></a>
        </div>
      </div>

    </div>


  </div>
</div>
