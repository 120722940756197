<div class="cont">
    <H2 class="head">{{'omp.head'|translate}}</H2>
    
</div>

<div class="cont">

    <div class="roww">
        <img src="assets/administration/Kondiba.png" alt="Фотография" width="250" height="320">
    </div>
</div>

<div class="cont">
    <p class="head5" [innerHTML]="'omp.text' | translate"></p>
    <docs-table [translations]="translations"></docs-table>
</div>


<div class="contt">
    <p class="head1" [innerHTML]="'omp.text2' | translate"></p>
    <table>
        <tbody>
            <tr>
                <td rowspan="4">СПЕЦИАЛЬНОСТЬ</td>
                <td>2-36 01 03</td>
                <td>Технологическое оборудование <br>машиностроительного <br>производства</td>
            </tr>
            <tr>
                <td>2-36 01 53</td>
                <td>Техническая эксплуатация оборудования</td>
            </tr>
            <tr>
                <td>5-04-0714-03</td>
                <td>Техническая эксплуатация <br>технологического оборудования <br>машиностроительного производства</td>
            </tr>
            <tr>
                <td>4-03-0714-02</td>
                <td>Эксплуатация и наладка <br>автоматизированного оборудования <br>машиностроительного производства</td>
            </tr>
            <tr>
                <td>СРОК ПОЛУЧЕНИЯ<br> ОБРАЗОВАНИЯ</td>
                <td colspan="2">в дневной форме на основе <br>общего базового образования – 3 года 6 месяцев <br>
    в дневной форме на основе<br> общего среднего образования – 2 года 6 месяцев</td>
            </tr>
            <tr>
                <td>КВАЛИФИКАЦИЯ <br>СПЕЦИАЛИСТА</td>
                <td colspan="2">Техник-механик</td>
            </tr>
            <tr>
                <td rowspan="3">КВАЛИФИКАЦИИ <br>РАБОЧЕГО</td>
                <td colspan="2">Оператор станков с программным управлением 3 - 4 разряда</td>
            </tr>
            <tr>
                <td colspan="2">Наладчик станков и <br>манипуляторов с программным управлением 5-го разряда</td>
            </tr>
            <tr>
                <td colspan="2">Слесарь-ремонтник</td>
            </tr>
        </tbody>
    </table>
</div>


<div class="cont">

    <p class="head1" [innerHTML]="'omp.text1' | translate"></p>
    <table>
        <tbody>
            <tr>
                <td rowspan="2">Курс</td>
                <td rowspan="2">№ группы</td>
                <td colspan="2">Количество учащихся</td>
                <td rowspan="2">Количество вакантных мест</td>
                <td rowspan="2">Куратор</td>
            </tr>
            <tr>
                <td>на момент  поступления</td>
                <td>на 01 февраля 2025г.</td>
            </tr>
            <tr>
                <td rowspan="3">I</td>
                <td>481</td>
                <td>27</td>
                <td>26</td>
                <td>1</td>
                <td>Бибчикова Анна Евгеньевна</td>
            </tr>
            <tr>
                <td>482</td>
                <td>26</td>
                <td>26</td>
                <td>0</td>
                <td>Пану Илона Ивановна</td>
            </tr>
            <tr>
                <td>483</td>
                <td>26</td>
                <td>26</td>
                <td>0</td>
                <td>Кудина Анастасия Михайловна</td>
            </tr>
            <tr>
                <td rowspan="3">II</td>
                <td>381</td>
                <td>28</td>
                <td>28</td>
                <td>0</td>
                <td>Рязанов Владимир Александрович</td>
            </tr>
            <tr>
                <td>345</td>
                <td>27</td>
                <td>27</td>
                <td>0</td>
                <td>Ковшик Юрий Леонидович</td>
            </tr>
            <tr>
                <td>346</td>
                <td>27</td>
                <td>26</td>
                <td>1</td>
                <td>Курдюмов Юрий Леонидович</td>
            </tr>
            <tr>
                <td rowspan="2">III</td>
                <td>245</td>
                <td>25</td>
                <td>22</td>
                <td>3</td>
                <td>Ковшик Кристина Геннадьевна</td>
            </tr>
            <tr>
                <td>281</td>
                <td>26</td>
                <td>27</td>
                <td>0</td>
                <td>Лукьяненко Ольга Владимировна</td>
            </tr>
            <tr>
                <td rowspan="2">IV</td>
                <td>181</td>
                <td>26</td>
                <td>26</td>
                <td>0</td>
                <td>Авраменко Николай Николаевич</td>
            </tr>
            <tr>
                <td>182</td>
                <td>26</td>
                <td>26</td>
                <td>0</td>
                <td>Горбач Алла Николаевна</td>
            </tr>
            <tr>
                <td></td>
                <td> <strong>ИТОГО:</strong></td>
                <td> <strong>264</strong></td>
                <td> <strong>260</strong></td>
                <td> <strong>5</strong></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>
<p class="head1" [innerHTML]="'omp.text3' | translate"></p>
<div class="cont">
    <div class="display-row">
        <img src="assets/components/otdelenia/naladchik.png" alt="Фотография" width="700" height="1000">
        <img src="assets/components/otdelenia/tex-mexKond.png" alt="Фотография" width="700" height="1000">
    </div>
</div>