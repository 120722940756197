<div class="cont">
    <H2 class="head">{{'news.head'|translate}}</H2>
</div>  
    <docs-table [translations]="translations"></docs-table>
<div class="cont">





    <p [innerHTML]="'news.text203' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/28-03-2025-1.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text202' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/27-03-2025-9.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/27-03-2025-10.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/27-03-2025-11.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/27-03-2025-12.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text201' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/27-03-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/27-03-2025-8.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text200' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/27-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/27-03-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/27-03-2025-6.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text199' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/27-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/27-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/27-03-2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text198' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/26-03-2025-9.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/26-03-2025-10.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text197' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/26-03-2025-6.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/26-03-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/26-03-2025-8.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text196' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/26-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/26-03-2025-5.png" alt="Фотография"  height="500">

    </div> 
    <p [innerHTML]="'news.text195' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/25-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/25-03-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/25-03-2025-6.png" alt="Фотография"  height="500">
    </div>  
    <p [innerHTML]="'news.text194' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/26-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/26-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/26-03-2025-3.png" alt="Фотография"  height="500">
    </div>   
    <p [innerHTML]="'news.text193' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/25-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/25-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/25-03-2025-3.png" alt="Фотография"  height="500">
    </div>    
    <p [innerHTML]="'news.text192' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/24-03-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/24-03-2025-8.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/24-03-2025-9.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text191' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/дод 2.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text190' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/24-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/24-03-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/24-03-2025-6.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text189' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/24-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/24-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/24-03-2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text188' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/21-03-2025-6.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text187' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/22-03-2025-6.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/22-03-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/22-03-2025-8.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text186' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/21-03-2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/21-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/21-03-2025-5.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text185' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/22-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/22-03-2025-5.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text184' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/22-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/22-03-2025-3.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text183' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/21-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/21-03-2025-2.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text182' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/20-03-2025-11.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/20-03-2025-12.png" alt="Фотография"  height="500">
    </div> 
    <div class="display-row">
        <img src="assets/components/News25Mart/20-03-2025-13.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/20-03-2025-14.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text181' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/20-03-2025-7.png" alt="Фотография"  height="500">
    </div> 
    <div class="display-row">
        <img src="assets/components/News25Mart/20-03-2025-8.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/20-03-2025-9.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/20-03-2025-10.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text180' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/20-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/20-03-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/20-03-2025-6.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text179' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/19-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/19-03-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/19-03-2025-6.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text178' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/20-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/20-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/20-03-2025-3.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text177' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/19-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/19-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/19-03-2025-3.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text176' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/18-03-2025-11.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/18-03-2025-12.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text175' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/18-03-2025-8.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/18-03-2025-9.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/18-03-2025-10.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text174' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/18-03-2025-4.png" alt="Фотография"  height="500">
    </div>    
    <div class="display-row">
        <img src="assets/components/News25Mart/18-03-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/18-03-2025-6.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/18-03-2025-7.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text173' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/18-03-2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text172' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/18-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/18-03-2025-2.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text171' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/22-03-2025-1.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text170' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/17-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/17-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/17-03-2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text169' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/16-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/16-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/16-03-2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text168' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/15-03-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/15-03-2025-8.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text167' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/15-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/15-03-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/15-03-2025-6.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text166' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/15-03-2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text165' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/14-03-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/14-03-2025-8.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/14-03-2025-9.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text164' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/14-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/14-03-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/14-03-2025-6.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text163' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/14-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/14-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/14-03-2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text162' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/13-03-2025-12.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/13-03-2025-13.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/13-03-2025-14.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text161' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/13-03-2025-9.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/13-03-2025-10.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/13-03-2025-11.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text160' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/13-03-2025-6.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/13-03-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/13-03-2025-8.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text159' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/13-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/13-03-2025-5.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text158' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/12-03-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/12-03-2025-8.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/12-03-2025-9.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text157' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/12-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/12-03-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/12-03-2025-6.png" alt="Фотография"  height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/News25Mart/15-03-2025-2.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text156' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/15-03-2025-1.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text155' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/13-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/13-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/13-03-2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text154' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/12-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/12-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/12-03-2025-3.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text153' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/11-03-2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/11-03-2025-4.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text152' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/11-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/11-03-2025-2.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text151' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/10-03-2025-14.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/10-03-2025-15.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/10-03-2025-16.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/10-03-2025-17.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text150' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/10-03-2025-11.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/10-03-2025-12.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/10-03-2025-13.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text149' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/10-03-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/10-03-2025-8.png" alt="Фотография"  height="500">
    </div>    
    <div class="display-row">
        <img src="assets/components/News25Mart/10-03-2025-9.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text148' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/10-03-2025-6.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text147' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/10-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/10-03-2025-5.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text146' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/10-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/10-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/10-03-2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text145' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/08-03-2025-6.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/08-03-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/08-03-2025-8.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/08-03-2025-9.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text144' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/08-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/08-03-2025-5.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text143' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/08-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/08-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/08-03-2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text142' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/05-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/05-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/05-03-2025-3.png" alt="Фотография"  height="500">
    </div>
    <div class="display-row">
        <img src="assets/banner-slides/8marta.jpg" alt="Фотография"  height="1000">
    </div>
    <p [innerHTML]="'news.text141' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/04-03-2025-1.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text140' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/03-03-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/03-03-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/03-03-2025-6.png" alt="Фотография"  height="500">

    </div>
    <p [innerHTML]="'news.text139' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/03-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/03-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/03-03-2025-3.png" alt="Фотография"  height="500">

    </div>
    <p [innerHTML]="'news.text138' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/02-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/02-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/02-03-2025-3.png" alt="Фотография"  height="500">

    </div>
    <p [innerHTML]="'news.text137' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/01-03-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/01-03-2025-6.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/01-03-2025-7.png" alt="Фотография"  height="500">

    </div>
    <p [innerHTML]="'news.text136' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25Mart/01-03-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/01-03-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/01-03-2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/News25Mart/01-03-2025-4.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text135' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/28-02-2025-14.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/28-02-2025-15.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/28-02-2025-16.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text134' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/28-02-2025-10.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/28-02-2025-11.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/28-02-2025-12.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/28-02-2025-13.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text133' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/27-02-2025-11.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/27-02-2025-12.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/27-02-2025-13.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text132' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/27-02-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/27-02-2025-8.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/27-02-2025-9.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/27-02-2025-10.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text131' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/28-02-2025-6.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/28-02-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/28-02-2025-8.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text130' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/28-02-2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/28-02-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/28-02-2025-5.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text129' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/27-02-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/27-02-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/27-02-2025-6.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text128' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/28-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/28-02-2025-2.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text127' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/27-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/27-02-2025-3.png" alt="Фотография"  height="500">
    </div> 
    <p [innerHTML]="'news.text126' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/26-02-2025-12.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/26-02-2025-13.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/26-02-2025-14.png" alt="Фотография"  height="500">
    </div>  
    <p [innerHTML]="'news.text125' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/26-02-2025-9.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/26-02-2025-10.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/26-02-2025-11.png" alt="Фотография"  height="500">
    </div>  
    <p [innerHTML]="'news.text124' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/26-02-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/26-02-2025-6.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/26-02-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/26-02-2025-8.png" alt="Фотография"  height="500">
    </div>  
    <p [innerHTML]="'news.text123' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/25-02-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/25-02-2025-6.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/25-02-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/25-02-2025-8.png" alt="Фотография"  height="500">
    </div>  
    <p [innerHTML]="'news.text122' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/26-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/26-02-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/26-02-2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/26-02-2025-4.png" alt="Фотография"  height="500">
    </div>  
    <p [innerHTML]="'news.text121' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/25-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/25-02-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/25-02-2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/25-02-2025-4.png" alt="Фотография"  height="500">
    </div>  
    <p [innerHTML]="'news.text120' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/24-02-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/24-02-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/24-02-2025-6.png" alt="Фотография"  height="500">
    </div>  
    <p [innerHTML]="'news.text119' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/24-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/24-02-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/24-02-2025-3.png" alt="Фотография"  height="500">
    </div>  
    <p [innerHTML]="'news.text118' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/22-02-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/22-02-2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/22-02-2025-4.png" alt="Фотография"  height="500">
    </div>    
    <div class="display-row">
        <img src="assets/components/News25/22-02-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/22-02-2025-6.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text117' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-02-2025-1.png" alt="Фотография"  height="500">
    </div>

    <p [innerHTML]="'news.text116' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/19-02-2025-6.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/19-02-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/19-02-2025-8.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text115' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-02-2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/21-02-2025-4.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text114' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/20-02-2025-9.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/20-02-2025-10.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text113' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/20-02-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/20-02-2025-8.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text112' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/20-02-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/20-02-2025-6.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text111' | translate"></p>
    <p [innerHTML]="'news.text110' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/20-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/20-02-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/20-02-2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/20-02-2025-4.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text109' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/19-02-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/19-02-2025-5.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text108' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/19-01-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/19-01-2025-5.png" alt="Фотография"  height="500">


    </div>
    <p [innerHTML]="'news.text107' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/19-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/19-02-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/19-02-2025-3.png" alt="Фотография"  height="500">

    </div>
    <p [innerHTML]="'news.text106' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/18-02-2025-12.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/18-02-2025-13.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/18-02-2025-14.png" alt="Фотография"  height="500">

    </div>
    <p [innerHTML]="'news.text105' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/18-02-2025-10.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/18-02-2025-11.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text104' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/18-02-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/18-02-2025-8.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/18-02-2025-9.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text103' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/18-02-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/18-02-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/18-02-2025-6.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text102' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/18-02-2025-1.png" alt="Фотография"  height="500">

        <img src="assets/components/News25/18-02-2025-3.png" alt="Фотография"  height="500">

    </div>
    <p [innerHTML]="'news.text101' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/21-02-2025-2.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text100' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/22-02-2025-1.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text99' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21.02.2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/21.02.2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/21.02.2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text98' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/15-02-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/15-02-2025-8.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/15-02-2025-9.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text97' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/11-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/11-02-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/11-02-2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text96' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/15-02-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/15-02-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/15-02-2025-6.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text95' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/15-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/15-02-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/15-02-2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text94' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/14-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/14-02-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/14-02-2025-3.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text93' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/14-01-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/14-01-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/14-01-2025-6.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text92' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/13-01-2025-10.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/13-01-2025-11.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/13-01-2025-12.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text91' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/13-01-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/13-01-2025-8.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/13-01-2025-9.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text90' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/13-01-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/13-01-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/13-01-2025-6.png" alt="Фотография"  height="500">
    </div>
    <p class="head" [innerHTML]="'library.text5' | translate"></p>
    <div class="display-row">
        <img src="assets/components/Libruary/13.02.2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/13.02.2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/13.02.2025-5.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text89' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/12-01-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/12-01-2025-8.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/12-01-2025-9.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text88' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/12-01-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/12-01-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/12-01-2025-6.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text87' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/11-01-2025-8.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/11-01-2025-9.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/11-01-2025-10.png" alt="Фотография"  height="500">
    </div>
    <p [innerHTML]="'news.text86' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/10-01-2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/10-01-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/10-01-2025-5.png" alt="Фотография"  height="500">
    </div>

    <p [innerHTML]="'news.text85' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/11-01-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/11-01-2025-6.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/11-01-2025-7.png" alt="Фотография"  height="500">

    </div>
    <p [innerHTML]="'news.text84' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/08-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/08-02-2025-2.png" alt="Фотография"  height="500">
    </div>

    <p [innerHTML]="'news.text83' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/07-02-2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/07-02-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/07-02-2025-5.png" alt="Фотография"  height="500">

    </div>

    <p [innerHTML]="'news.text82' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/07-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/07-02-2025-2.png" alt="Фотография"  height="500">
    </div>

    <p [innerHTML]="'news.text81' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/06-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/06-02-2025-2.png" alt="Фотография"  height="500">
    </div>

    <p [innerHTML]="'news.text80' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/05-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/05-02-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/05-02-2025-3.png" alt="Фотография"  height="500">
    </div>

    <p [innerHTML]="'news.text79' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/04-02-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/04-02-2025-5.png" alt="Фотография"  height="500">
    </div>

    <p [innerHTML]="'news.text78' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/04-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/04-02-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/04-02-2025-3.png" alt="Фотография"  height="500">
    </div>

    <p [innerHTML]="'news.text77' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/03-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/03-02-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/03-02-2025-3.png" alt="Фотография"  height="500">
    </div>

    <p [innerHTML]="'news.text76' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/31-01-2025-5.png" alt="Фотография"  height="500">
    </div>

    <p [innerHTML]="'news.text75' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/31-01-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/31-01-2025-2.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/31-01-2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/31-01-2025-4.png" alt="Фотография"  height="500">
    </div>

  
    <p [innerHTML]="'news.text74' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/30-01-2025-4.png" alt="Фотография"  height="500">
        <img src="assets/components/News25/30-01-2025-5.png" alt="Фотография"  height="500">

    </div>

    <p [innerHTML]="'news.text73' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/29-01-2025-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/29-01-2025-2.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/29-01-2025-3.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/29-01-2025-4.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text72' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/30-01-2025-1.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/30-01-2025-2.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/30-01-2025-3.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text71' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/28-01-2025-9.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/28-01-2025-10.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/28-01-2025-11.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text70' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/28-01-2025-6.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/28-01-2025-7.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/28-01-2025-8.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text69' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/28-01-2025-3.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/28-01-2025-4.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/28-01-2025-5.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text68' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/28-01-2025-1.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/28-01-2025-2.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text67' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/26-01-2025-6.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/26-01-2025-7.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/26-01-2025-8.png" alt="Фотография" width="600" height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/News24/vibori.png" alt="Фотография" width="1000" height="500">

    </div>

    <p [innerHTML]="'news.text66' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/26-01-2025-2.png" alt="Фотография" width="350" height="500">
        <img src="assets/components/News25/26-01-2025-3.png" alt="Фотография" width="500" height="500">
        <img src="assets/components/News25/26-01-2025-4.png" alt="Фотография" width="350" height="500">
        <img src="assets/components/News25/26-01-2025-5.png" alt="Фотография" width="350" height="500">

    </div>

    <p [innerHTML]="'news.text65' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/26-01-2025-1.png" alt="Фотография" width="750" height="500">

    </div>

    <p [innerHTML]="'news.text64' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/25-01-2025-3.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/25-01-2025-4.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/25-01-2025-5.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text63' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/25-01-2025-1.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/25-01-2025-2.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text62' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-22.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/23-01-2025-23.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/23-01-2025-24.png" alt="Фотография" width="600" height="500">
    </div>
    <p [innerHTML]="'news.text61' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-19.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/23-01-2025-20.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/23-01-2025-21.png" alt="Фотография" width="400" height="500">
    </div>
    <p [innerHTML]="'news.text60' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-16.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/23-01-2025-17.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/23-01-2025-18.png" alt="Фотография" width="400" height="500">
    </div>
    <p [innerHTML]="'news.text59' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-13.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/23-01-2025-14.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/23-01-2025-15.png" alt="Фотография" width="400" height="500">
    </div>
    <p [innerHTML]="'news.text58' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-12.png" alt="Фотография" width="400" height="500">
    </div>
    <p [innerHTML]="'news.text57' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-9.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/23-01-2025-10.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/23-01-2025-11.png" alt="Фотография" width="800" height="500">
    </div>
    <p [innerHTML]="'news.text56' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-6.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/23-01-2025-7.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/23-01-2025-8.png" alt="Фотография" width="800" height="500">
   </div>

    <p [innerHTML]="'news.text55' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-4.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/23-01-2025-5.png" alt="Фотография" width="800" height="500">
    </div>

    <p [innerHTML]="'news.text54' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/23-01-2025-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/23-01-2025-2.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/23-01-2025-3.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text53' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/22-01-2025-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/22-01-2025-2.png" alt="Фотография" width="650" height="500">
        <img src="assets/components/News25/22-01-2025-3.png" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text52' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-25.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/21-01-2025-26.png" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text51' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-22.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-23.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/21-01-2025-24.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text50' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-20.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-21.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text49' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-18.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-19.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text48' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-15.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/21-01-2025-16.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/21-01-2025-17.png" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text47' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-12.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-13.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-14.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text46' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-10.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-11.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text45' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-8.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/21-01-2025-9.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text44' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-5.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/21-01-2025-6.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/21-01-2025-7.png" alt="Фотография" width="600" height="500">


    </div>

    <p [innerHTML]="'news.text43' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/21-01-2025-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/21-01-2025-2.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/21-01-2025-3.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/21-01-2025-4.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text42' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/20-01-2025-3.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/20-01-2025-4.png" alt="Фотография" width="800" height="500">

    </div>

    <p [innerHTML]="'news.text41' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/20-01-2025-2.png" alt="Фотография" width="450" height="500">
    </div>

    <p [innerHTML]="'news.text40' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/20-01-2025-1.png" alt="Фотография" width="400" height="600">
    </div>

    <p [innerHTML]="'news.text39' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/19-01-2025-1.png" alt="Фотография" width="900" height="500">
        <img src="assets/components/News25/19-01-2025-2.png" alt="Фотография" width="900" height="500">
    </div>

    <p [innerHTML]="'news.text38' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/17-01-2025-6.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/17-01-2025-7.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/17-01-2025-8.png" alt="Фотография" width="700" height="500">
    </div>

    <p [innerHTML]="'news.text37' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/17-01-2025-4.png" alt="Фотография" width="900" height="500">
        <img src="assets/components/News25/17-01-2025-5.png" alt="Фотография" width="700" height="500">


    </div>

    <p [innerHTML]="'news.text36' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/17-01-2025-1.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/17-01-2025-2.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/17-01-2025-3.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text35' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/16-01-2025-11.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/16-01-2025-12.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/16-01-2025-13.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text34' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/16-01-2025-8.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/16-01-2025-9.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/16-01-2025-10.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text33' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/16-01-2025-4.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/16-01-2025-5.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/16-01-2025-7.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text32' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/16-01-2025-1.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/16-01-2025-2.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/16-01-2025-3.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text31' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/15-01-2025-4.png" alt="Фотография" width="750" height="500">
        <img src="assets/components/News25/15-01-2025-5.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/15-01-2025-6.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text30' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/14-01-2025-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/14-01-2025-2.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/14-01-2025-3.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text29' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/12-01-2025-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/12-01-2025-2.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/12-01-2025-3.png" alt="Фотография" width="400" height="500">

    </div>

    <p [innerHTML]="'news.text28' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/15-01-2025-1.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/15-01-2025-2.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/15-01-2025-3.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text27' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/13-01-2025-1.png" alt="Фотография" width="500" height="500">
        <img src="assets/components/News25/13-01-2025-2.png" alt="Фотография" width="500" height="500">
        <img src="assets/components/News25/13-01-2025-3.png" alt="Фотография" width="500" height="500">

    </div>

    <p [innerHTML]="'news.text26' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/11-01-2025-4.png" alt="Фотография" width="800" height="500">

    </div>

    <p [innerHTML]="'news.text25' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/11-01-2025-3.png" alt="Фотография" width="800" height="500">

    </div>

    <p [innerHTML]="'news.text24' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/11-01-2025-1.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News25/11-01-2025-2.png" alt="Фотография" width="800" height="500">



    </div>

    <p [innerHTML]="'news.text23' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/10-01-2025-1.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News25/10-01-2025-2.png" alt="Фотография" width="700" height="500">



    </div>

    <p [innerHTML]="'news.text22' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/09-01-2025-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/09-01-2025-2.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News25/09-01-2025-3.png" alt="Фотография" width="400" height="500">


    </div>

    <p [innerHTML]="'news.text21' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/08-01-2025-4.png" alt="Фотография" width="500" height="500">
        <img src="assets/components/News25/08-01-2025-6.png" alt="Фотография" width="500" height="500">
        <img src="assets/components/News25/08-01-2025-7.png" alt="Фотография" width="500" height="500">


    </div>

    <p [innerHTML]="'news.text20' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News25/08-01-2025-1.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News25/08-01-2025-2.png" alt="Фотография" width="700" height="500">


    </div>

    <p [innerHTML]="'news.text19' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/08-01-2025-1.png" alt="Фотография" width="1000" height="500">
        <img src="assets/components/News24/08-01-2025-2.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News24/08-01-2025-3.png" alt="Фотография" width="600" height="500">

    </div>

    <p [innerHTML]="'news.text18' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/31-12-2024-1.png" alt="Фотография" width="1000" height="500">
        <img src="assets/components/News24/31-12-2024-2.png" alt="Фотография" width="600" height="500">
    </div>
    
    <p [innerHTML]="'news.text17' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/28-12-2024-7.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News24/28-12-2024-8.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News24/28-12-2024-9.png" alt="Фотография" width="600" height="500">



    </div>

    <p [innerHTML]="'news.text16' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/28-12-2024-4.png" alt="Фотография" width="600" height="500">

        <img src="assets/components/News24/28-12-2024-5.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News24/28-12-2024-6.png" alt="Фотография" width="400" height="500">



    </div>

    <p [innerHTML]="'news.text15' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/28-12-2024-1.png" alt="Фотография" width="400" height="500">

        <img src="assets/components/News24/28-12-2024-2.png" alt="Фотография" width="800" height="500">
        <img src="assets/components/News24/28-12-2024-3.png" alt="Фотография" width="400" height="500">



    </div>


    <p [innerHTML]="'news.text14' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/27-12-2024-3.png" alt="Фотография" width="600" height="500">

        <img src="assets/components/News24/27-12-2024-5.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News24/27-12-2024-6.png" alt="Фотография" width="600" height="500">



    </div>

    <p [innerHTML]="'news.text13' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/27-12-2024-1.png" alt="Фотография" width="350" height="500">
        <img src="assets/components/News24/27-12-2024-2.png" alt="Фотография" width="1000" height="500">



    </div>




   







 
</div>