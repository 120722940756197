<div class="cont">
    <H2 class="head">{{'prof-styd.head'|translate}}</H2>
 
</div>  

<docs-table [translations]="translations"></docs-table>
<div class="cont">
<p class="head1" [innerHTML]="'prof-styd.text' | translate"></p>
<p class="head1" [innerHTML]="'prof-styd.text1' | translate"></p>

<p [innerHTML]="'prof-styd.text11' | translate"></p>
<div class="display-row">
    <img src="assets/components/prof-styd/18-02-2025-1.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/18-02-2025-2.png" alt="Фотография" height="500">
</div>
<div class="display-row">
    <img src="assets/components/prof-styd/18-02-2025-3.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/18-02-2025-4.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/18-02-2025-5.png" alt="Фотография" height="500">
</div>
<div class="display-row">
    <img src="assets/components/prof-styd/18-02-2025-6.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/18-02-2025-7.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/18-02-2025-8.png" alt="Фотография" height="500">
</div>
<p [innerHTML]="'prof-styd.text9' | translate"></p>
<div class="display-row">
    <img src="assets/components/prof-styd/11-12-2024-1.png" alt="Фотография" height="500">
</div>

<p [innerHTML]="'prof-styd.text10' | translate"></p>
<div class="display-row">
    <img src="assets/components/prof-styd/27-11-2024-1.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/27-11-2024-2.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/27-11-2024-3.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/27-11-2024-4.png" alt="Фотография" height="500">
</div>
<div class="display-row">
    <img src="assets/components/prof-styd/27-11-2024-5.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/27-11-2024-6.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/27-11-2024-7.png" alt="Фотография" height="500">
</div>

<p [innerHTML]="'prof-styd.text8' | translate"></p>
<div class="display-row">
    <img src="assets/components/prof-styd/19-11-2024-1.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/19-11-2024-2.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/19-11-2024-3.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/19-11-2024-4.png" alt="Фотография" height="500">
</div>
<div class="display-row">
    <img src="assets/components/prof-styd/19-11-2024-5.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/19-11-2024-6.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/19-11-2024-7.png" alt="Фотография" height="500">
</div>

<p [innerHTML]="'prof-styd.text7' | translate"></p>
<div class="display-row">
    <img src="assets/components/prof-styd/20-11-2024-1.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/20-11-2024-2.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/20-11-2024-3.png" alt="Фотография" height="500">
</div>
<div class="display-row">
    <img src="assets/components/prof-styd/20-11-2024-4.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/20-11-2024-5.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/20-11-2024-6.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/20-11-2024-7.png" alt="Фотография" height="500">
</div>

<p [innerHTML]="'prof-styd.text6' | translate"></p>
<div class="display-row">
    <img src="assets/components/prof-styd/15-11-2024-1.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/15-11-2024-2.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/15-11-2024-5.png" alt="Фотография" height="500">
</div>
<div class="display-row">

    <img src="assets/components/prof-styd/15-11-2024-3.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/15-11-2024-4.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/15-11-2024-6.png" alt="Фотография" height="500">
</div>

<p [innerHTML]="'prof-styd.text5' | translate"></p>
<div class="display-row">
    <img src="assets/components/prof-styd/31-10-2024-1.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/31-10-2024-2.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/31-10-2024-3.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/31-10-2024-4.png" alt="Фотография" height="500">
</div>

<p [innerHTML]="'prof-styd.text4' | translate"></p>
<div class="display-row">
    <img src="assets/components/prof-styd/16-10-2024-1.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/16-10-2024-2.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/16-10-2024-3.png" alt="Фотография" height="500">
</div>
<div class="display-row">
    <img src="assets/components/prof-styd/16-10-2024-4.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/16-10-2024-5.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/16-10-2024-6.png" alt="Фотография" height="500">
</div>

<p [innerHTML]="'prof-styd.text3' | translate"></p>
<div class="display-row">
    <img src="assets/components/prof-styd/25-04-2024-1.jpg" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/25-04-2024-2.jpg" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/25-04-2024-3.jpg" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/25-04-2024-4.jpg" alt="Фотография" height="500">
</div>
<div class="display-row">
    <img src="assets/components/prof-styd/25-04-2024-5.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/25-04-2024-6.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/25-04-2024-7.png" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/25-04-2024-8.png" alt="Фотография" height="500">
</div>
<div class="display-row">
    <img src="assets/components/prof-styd/25-04-2024-9.png" alt="Фотография" height="500">
</div>


<p [innerHTML]="'prof-styd.text2' | translate"></p>
<div class="display-row">
    <img src="assets/components/prof-styd/16-04-2024-1.jpg" alt="Фотография" height="500">
</div>

<div class="display-row">
    <img src="assets/components/prof-styd/16-04-2024-2.jpg" alt="Фотография" height="500">
    <img src="assets/components/prof-styd/16-04-2024-3.jpg" alt="Фотография" height="500">
</div>
</div>