<div class="cont">
  <div class="news-search-wrapper">
    <div class="news-block">
      <h3>{{'main-page.news-search.header' | translate}}</h3>
      <div class="news" >
          <ng-container *ngFor="let n of news | slice:0:3" >
            <news-component [i-news]="n"></news-component>
          </ng-container>
          


          <button mat-raised-button class="blue-button">
            <a href="news" style="color: white">{{'main-page.news-search.all-news' | translate}}</a>
          </button>
          

      </div>
    </div>
    <div class="search-block">
      <div class="search">
        <h3>{{'main-page.news-search.search' | translate}}</h3>
        <form class="example-form">
          <input type="text"
                 placeholder="Текст"
                 [formControl]="control"
                 [matAutocomplete]="auto"
                 class="example-input">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let street of filteredStreets | async" [value]="street">
              {{street}}
            </mat-option>
          </mat-autocomplete>
        </form>
      </div>
      <div class="media">
        <h3>{{'main-page.news-search.media' | translate}}</h3>
        <div class="icons">
          <div class="icon">
            <a href="https://m.vk.com/college.ripoby">
              <img src="assets/vk.png" alt="">
            </a>
          </div>
          <div class="icon">
            <a href="https://t.me/kstmia_uo_ripo">
              <img src="assets/telegram.png" alt="">
            </a>
          </div>
          <div class="icon">
            <a href="https://instagram.com/kstmia_uo_ripo?igshid=YmMyMTA2M2Y=">
              <img src="assets/instagram.png" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
