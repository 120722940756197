<div class="cont">
    <H2 class="head">{{'oi-ra.head'|translate}}</H2>
    
</div>

<div class="cont">

    <div class="roww">
        <img src="assets/administration/permyakova.jpg" alt="Фотография" width="250" height="320">
    </div>
</div>

<div class="cont">
    <p class="head5" [innerHTML]="'oi-ra.text' | translate"></p>
    <docs-table [translations]="translations"></docs-table>
</div>


<div class="contt">
    <p class="head1" [innerHTML]="'oi-ra.text1' | translate"></p>
        <table >
            <tbody>
                <tr>
                    <td rowspan="2">СПЕЦИАЛЬНОСТЬ</td>
                    <td><strong>2-37 01 51</strong></td>
                    <td><strong>Автосервис</strong></td>
                </tr>
                <tr>
                    <td><strong>4-03-0715-01</strong></td>
                    <td><strong>Обслуживание и ремонт автомобилей</strong></td>
                </tr>
                <tr>
                    <td>СРОК ПОЛУЧЕНИЯ <br>       ОБРАЗОВАНИЯ</td>
                    <td colspan="2">В дневной форме на основе общего базового образования – 3 года 6 месяцев</td>
                </tr>
                <tr>
                    <td>КВАЛИФИКАЦИЯ</td>
                    <td colspan="2"><strong>Автомеханик 5-го разряда</strong></td>
                </tr>
                <tr>
                    <td rowspan="3">КВАЛИФИКАЦИИ <br>        РАБОЧЕГО</td>
                    <td colspan="2">Слесарь по ремонту автомобилей 3-4 разряда</td>
                </tr>
                <tr>
                    <td colspan="2">Монтировщик шин и шинно-пневматических муфт 2-го разряда</td>
                </tr>
                <tr>
                    <td colspan="2">Водитель автомобиля (право управления механическими транспортными <br>
        средствами категорий «В» и «С», «С»)</td>
                </tr>
            </tbody>
        </table>
</div>



<div class="cont">
    <p class="head1" [innerHTML]="'oi-ra.text2' | translate"></p>
    <table>
        <tbody>
                <tr>
                    <td rowspan="2">СПЕЦИАЛЬНОСТЬ</td>
                    <td><strong>2-26 02 32</strong></td>
                    <td><strong>Операционная деятельность в логистике</strong></td>
                </tr>
                <tr>
                    <td><strong>5-04-0412-02</strong></td>
                    <td><strong>Операционная деятельность в логистике</strong></td>
                </tr>
                <tr>
                    <td>СРОК ПОЛУЧЕНИЯ <br>       ОБРАЗОВАНИЯ</td>
                    <td colspan="2">В дневной форме на основе общего базового образования – 2 года 10 месяцев</td>
                </tr>
                <tr>
                    <td>КВАЛИФИКАЦИЯ <br>        СПЕЦИАЛИСТА</td>
                    <td colspan="2"><strong>Операционный логист</strong></td>
                </tr>
                <tr>
                    <td rowspan="2">КВАЛИФИКАЦИИ <br>        РАБОЧЕГО</td>
                    <td colspan="2">Кладовщик 4-5 разряда</td>
                </tr>
                <tr>
                    <td colspan="2">Агент по обслуживанию автомобильных перевозок</td>
                </tr>
        </tbody>
    </table>
</div>

<div class="cont">
    <p class="head1" [innerHTML]="'oi-ra.text2' | translate"></p>
    <table>
        <tbody>
            <tr>
                <td><strong>СПЕЦИАЛЬНОСТЬ</strong></td>
                <td><strong>5-04-0716-04</strong></td>
                <td><strong>Эксплуатация средств измерения</strong></td>
            </tr>
            <tr>
                <td>СРОК ПОЛУЧЕНИЯ <br>ОБРАЗОВАНИЯ</td>
                <td colspan="2">В дневной форме на основе общего базового образования – 3 года 6 месяцев</td>
            </tr>
            <tr>
                <td>КВАЛИФИКАЦИЯ <br>СПЕЦИАЛИСТА</td>
                <td colspan="2"><strong>Техник-метролог</strong></td>
            </tr>
            <tr>
                <td rowspan="2">КВАЛИФИКАЦИИ <br>РАБОЧЕГО</td>
                <td colspan="2">Контролер станочных и слесарных работ 3-го разряда</td>
            </tr>
            <tr>
                <td colspan="2">Контролер измерительных приборов и специального инструмента 3-го разряда</td>
            </tr>
        </tbody>
    </table>
</div>



<p class="head1" [innerHTML]="'oi-ra.text4' | translate"></p>
<div class="cont">
    <table>
        <tbody>
            <tr>
                <td rowspan="2">Курс</td>
                <td rowspan="2">№ группы</td>
                <td colspan="2">Количество учащихся</td>
                <td rowspan="2">Количество вакантных мест</td>
                <td rowspan="2">Куратор</td>
            </tr>
            <tr>
                <td>на момент
    поступления</td>
                <td>на 01 сентября
    2024 г.</td>
            </tr>
            <tr>
                <td rowspan="3">I</td>
                <td>461</td>
                <td>28</td>
                <td>28</td>
                <td>0</td>
                <td>Бакун Яна Руслановна</td>
            </tr>
            <tr>
                <td>471</td>
                <td>29</td>
                <td>29</td>
                <td>0</td>
                <td>Леонова Светлана Эдуардовна</td>
            </tr>
            <tr>
                <td>491</td>
                <td>25</td>
                <td>25</td>
                <td>0</td>
                <td>Терешко Полина Адреевна</td>
            </tr>
            <tr>
                <td rowspan="2">II</td>
                <td>371</td>
                <td>28</td>
                <td>29</td>
                <td>0</td>
                <td>Алексеев Андрей Николаевич</td>
            </tr>
            <tr>
                <td>361</td>
                <td>28</td>
                <td>28</td>
                <td>0</td>
                <td>Финько Светлана Константиновна</td>
            </tr>
            <tr>
                <td rowspan="2">III</td>
                <td>261</td>
                <td>28</td>
                <td>28</td>
                <td>0</td>
                <td>Шелесная Ольга Николаевна</td>
            </tr>
            <tr>
                <td>271</td>
                <td>28</td>
                <td>28</td>
                <td>0</td>
                <td>Николаева Ирина Николаевна</td>
            </tr>
            <tr>
                <td>IV</td>
                <td>171</td>
                <td>28</td>
                <td>27</td>
                <td>1</td>
                <td>Николаева Ирина Николаевна</td>
            </tr>
            <tr>
                <td></td>
                <td><strong>ИТОГО</strong></td>
                <td><strong>222</strong></td>
                <td><strong>222</strong></td>
                <td><strong>1</strong></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>


<p class="head1" [innerHTML]="'oi-ra.text3' | translate"></p>



<div class="cont">

    <div class="display-row">
        <img src="assets/components/otdelenia/Avtomex.png" alt="Фотография" width="700" height="1000">
        <img src="assets/components/otdelenia/log.png" alt="Фотография" width="700" height="1000">
    </div>
</div>


<div class="cont">

    <div class="display-row">
        <img src="assets/components/otdelenia/tex-metrolog.png" alt="Фотография" width="700" height="1000">
    </div>
</div>