import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocsTableComponent } from '../../docs-table/docs-table.component';
import { AdditionalEduCardComponent } from "../../main-page/additional-edu-card/additional-edu-card.component";

@Component({
    selector: 'app-competencecenter',
    standalone: true,
    templateUrl: './competencecenter.component.html',
    styleUrls: ['./competencecenter.component.sass'],
    imports: [CommonModule, DocsTableComponent, TranslateModule, AdditionalEduCardComponent]
})
export class CompetencecenterComponent {
[x: string]: any;
  bannerImage: string = 'assets/components/trainee/banner.png';
  translations: string = 'competencecenter';
}
