import { Page } from './../../model/page';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { MatMenuPanel } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

@Component({
  selector: 'header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent {
  langSelections: any[] = [
    { value: 'RU', code: 'ru' },
    { value: 'BY', code: 'by' },
    { value: 'EN', code: 'en' },
  ];
  selected: any;
  menus: string[];
rab: MatMenuPanel<any>|null;
methodical: MatMenuPanel<any>|null;

  constructor(
    private translateService: TranslateService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private _document: Document
  ){
    this.getTranslationByName("trainees");

  }

  ngOnInit(): void{
    this.updateRootLang('ru');
    this.selected = this.langSelections[0];
    this.route.queryParams.subscribe((params) => {
      const lang = params['lang'];
      const defaultLanguage = 'ru';
      this.selected = this.findLanguageInAvailableSelections([
        lang,
        defaultLanguage,
      ]);
      this.changeLanguage();
    });
  }

  changeLanguage(): void {
    this.translateService.use(this.selected.code);
    this.updateRootLang(this.selected.code);
  }

  changeFont() {

  }

  showMenu() {
    const menu = document.querySelector('#menu');
    const navButton = document.getElementById('nav-button');

    if(menu?.classList.contains('horizontal-menu')) {
      menu.classList.remove('horizontal-menu')
      menu.classList.add('vertical-menu')
      navButton?.style.setProperty('padding-bottom', '1rem')
    } else {
      menu?.classList.add('horizontal-menu')
      navButton?.style.removeProperty('padding-bottom')
    }
  }

  checkIfLanguageInAvailableSelections(lang: any) {
    return (
      lang &&
      this.langSelections.some((obj) =>
        Object.values(obj).some((val: any) => val.toString().includes(lang))
      )
    );
  }

  findLanguageInAvailableSelections(languages: any) {
    for (const lang of languages) {
      if (lang && this.checkIfLanguageInAvailableSelections(lang)) {
        return this.langSelections.find((obj) =>
          Object.values(obj).some((val: any) => val.toString().includes(lang))
        );
      }
    }
  }

  getTranslationByName(name: string){
    return this.translateService.get('header.' + name).pipe(map((res: any) => {
      this.menus = res
      console.log(this.menus);
      return res;
    }))
  }

  updateRootLang(lang: any){
    this.renderer.setAttribute(this._document.documentElement, 'lang', lang);
  }

  navigateTo(path: string){
    this.router.navigate([path])
  }

  openSite(path: string){
    window.open(path, "_blank")
  }
}
