import { Component } from '@angular/core';
import { Slide } from 'src/app/model/slide';
@Component({
  selector: 'main-component',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.sass']
})
export class MainComponent {
  slides: Slide[] = [
    {path: "assets/banner-slides/23-11-2024-1.png", tag:"main_abitur"},


  ]
}
