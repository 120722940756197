<div class="cont">
    <H2 class="head">{{'news.head'|translate}}</H2>
</div>  
    <docs-table [translations]="translations"></docs-table>
<div class="cont">


    <div class="display-row">
        <img src="assets/components/News24/vibori.png" alt="Фотография" width="1000" height="500">

    </div>






    <p [innerHTML]="'news.text4' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/13-12-2024-4.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News24/13-12-2024-5.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News24/13-12-2024-6.png" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text3' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/13-12-2024-1.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News24/13-12-2024-2.png" alt="Фотография" width="700" height="500">
        <img src="assets/components/News24/13-12-2024-3.png" alt="Фотография" width="400" height="500">
    </div>

    <p [innerHTML]="'news.text2' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/12-12-2024-4.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News24/12-12-2024-5.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News24/12-12-2024-6.png" alt="Фотография" width="600" height="500">
    </div>

    <p [innerHTML]="'news.text1' | translate"></p>
    <div class="display-row">
        <img src="assets/components/News24/12-12-2024-1.png" alt="Фотография" width="600" height="500">
        <img src="assets/components/News24/12-12-2024-2.png" alt="Фотография" width="400" height="500">
        <img src="assets/components/News24/12-12-2024-3.png" alt="Фотография" width="600" height="500">
    </div>





 
</div>