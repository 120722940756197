import { Component } from '@angular/core';
import { Slide } from 'src/app/model/slide';
@Component({
  selector: 'main-component',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.sass']
})
export class MainComponent {
  slides: Slide[] = [

    {path: "assets/banner-slides/main_abitur.png", tag:"main_abitur"},
    {path: "assets/banner-slides/sport.jpg", tag:"main_abitur"},
    {path: "assets/banner-slides/studs.jpg", tag:"main_abitur"},
    {path: "assets/banner-slides/studs1.jpg", tag:"main_abitur"},



  ]
}
