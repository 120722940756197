<div class="cont">
    <H2 class="head">{{'yo.head'|translate}}</H2>
</div>

    <docs-table [translations]="translations"></docs-table>

<div class="cont">
    <p class="head1" [innerHTML]="'yo.text' | translate"></p>
    <p class="head" [innerHTML]="'yo.text1' | translate"></p>

    <p class="head1" [innerHTML]="'yo.text2' | translate"></p>
    <p class="head" [innerHTML]="'yo.text3' | translate"></p>
</div>
<docs-table [translations]="translations"></docs-table>
