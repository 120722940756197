<div class="cont">
    <H2 class="head">{{'cooperation.head'|translate}}</H2>
    
</div>

<docs-table [translations]="translations"></docs-table>
<div class="cont">
    <p class="head1" [innerHTML]="'cooperation.text' | translate"></p>
    <p class="head" [innerHTML]="'cooperation.text1' | translate"></p>
    <p class="head" [innerHTML]="'cooperation.text2' | translate"></p>

</div>