<div class="cont">
    <H2 class="head">{{'library.head'|translate}}</H2>
    
</div>

<div class="cont">
    <p class="head1" [innerHTML]="'library.text' | translate"></p>
    <docs-table [translations]="translations"></docs-table>


    <p class="head" [innerHTML]="'library.text6' | translate"></p>
    <div class="display-row">
        <img src="assets/components/Libruary/26-02-2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/26-02-2025-2.png" alt="Фотография"  height="500">        
    </div>
    <div class="display-row">
        <img src="assets/components/Libruary/26-02-2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/26-02-2025-4.png" alt="Фотография"  height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/Libruary/26-02-2025-5.png" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/26-02-2025-6.png" alt="Фотография"  height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/Libruary/26-02-2025-7.png" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/26-02-2025-10.png" alt="Фотография"  height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/Libruary/26-02-2025-8.png" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/26-02-2025-9.png" alt="Фотография"  height="500">
    </div>
    <p class="head" [innerHTML]="'library.text5' | translate"></p>
    <div class="display-row">
        <img src="assets/components/Libruary/13.02.2025-1.png" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/13.02.2025-2.png" alt="Фотография"  height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/Libruary/13.02.2025-3.png" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/13.02.2025-4.png" alt="Фотография"  height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/Libruary/13.02.2025-5.png" alt="Фотография"  height="500">
    </div>
    <p class="head" [innerHTML]="'library.text3' | translate"></p>
    <div class="display-row">
        <img src="assets/components/Libruary/Геноцид 1.jpg" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/г 2.jpg" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/г 3.jpg" alt="Фотография"  height="500">
    </div>
    <div class="display-row">
        <img src="assets/components/Libruary/г 4.jpg" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/г 5.jpg" alt="Фотография"  height="500">
    </div>

    <p class="head" [innerHTML]="'library.text4' | translate"></p>
    <div class="display-row">
        <img src="assets/components/Libruary/Выборы 1.jpg" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/Выборы 2.jpg" alt="Фотография"  height="500">
    </div>

    <p class="head" [innerHTML]="'library.text2' | translate"></p>
    <div class="display-row">
        <img src="assets/components/Libruary/ПО 1.jpg" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/ПО 2.jpg" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/ПО 3.jpg" alt="Фотография"  height="500">
    </div>    
    <div class="display-row">
        <img src="assets/components/Libruary/ПО 4.jpg" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/ПО 5.jpg" alt="Фотография"  height="500">
    </div>

    <p class="head" [innerHTML]="'library.text1' | translate"></p>
    <div class="display-row">
        <img src="assets/components/Libruary/17-09-2024-1.png.jpg" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/17-09-2024-2.png.jpg" alt="Фотография"  height="500">
        <img src="assets/components/Libruary/17-09-2024-3.png.jpg" alt="Фотография"  height="500">
    </div>

    <div class="display-row">
        <img src="assets/components/Libruary/Мер1.png" alt="Фотография" height="500">
        <img src="assets/components/Libruary/Мер2.png" alt="Фотография" height="500">
        <img src="assets/components/Libruary/Мер3.png" alt="Фотография" height="500">
    </div>

    <div class="display-row">
        <img src="assets/components/Libruary/Мер4.png" alt="Фотография" height="500">
    </div>

    <div class="display-row">
        <img src="assets/components/Libruary/15-01-2024-1.png" alt="Фотография" height="500">
        <img src="assets/components/Libruary/15-01-2024-2.png" alt="Фотография" height="500">
        <img src="assets/components/Libruary/15-01-2024-3.png" alt="Фотография" height="500">


    </div>

    <div class="display-row">

        <img src="assets/components/Libruary/15-01-2024-4.png" alt="Фотография" height="500">
        <img src="assets/components/Libruary/15-01-2024-5.png" alt="Фотография" height="500">

    </div>

</div>