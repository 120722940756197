<div class="cont">
    <H2 class="head">{{'schedule-kstmia.head'|translate}}</H2>
    <p [innerHTML]="'schedule-kstmia.text' | translate"></p>

<docs-table [translations]="translations"></docs-table>
<div class="table">
    <p class="head1" [innerHTML]="'schedule-kstmia.text1' | translate"></p>
    <table>
        <tbody>
            <tr>
                <td width="25%" class="head1">Должность</td>
                <td class="head1">Фамилия Имя Отчество</td>
                <td class="head1">Кабинет</td>
                <td class="head1">Телефон</td>
                <td class="head1">Дни недели</td>
                <td class="head1">Время</td>
                <td class="head1">Адрес электронной почты</td>
            </tr>
            <tr>
                <td width="25%">Директор</td>
                <td>РЫБАК Артём Евгеньевич</td>
                <td>202</td>
                <td>396 36 25</td>
                <td>2-я, 4-я среда</td>
                <td>14.00 - 19.00</td>
                <td> <a href="mailto:director@college-ripo.by">director@college-ripo.by </a></td>
            </tr>
            <tr>
                <td width="25%"> Заместитель директора по учебной работе</td>
                <td> ПЕРСКАЯ Татьяна Анатольевна</td>
                <td> 218</td>
                <td>318 59 71</td>
                <td>1-я, 3-я среда</td>
                <td>14.00 - 19.00 </td>
                <td><a href="mailto:director@college-ripo.by">director@college-ripo.by </a></td>
            </tr>
            <tr>
                <td width="25%">Заместитель директора по воспитательной работе</td>
                <td>ХОТЬКО Людмила Михайловна</td>
                <td>215</td>
                <td>374 59 92</td>
                <td>2-я, 4-я пятница</td>
                <td>14.00 - 20.00 </td>
                <td><a href="mailto:director@college-ripo.by">director@college-ripo.by </a></td>
            </tr>
            <tr>
                <td width="25%"> Заместитель директора по производственному обучению</td>
                <td> ОКУНЕВ Валерий Евгеньевич</td>
                <td> 103</td>
                <td>373 40 52</td>
                <td>2-й, 4-й вторник</td>
                <td>14.00 - 19.00</td>
                <td><a href="mailto:director@college-ripo.by">director@college-ripo.by </a></td>
            </tr>
            <tr>
                <td width="25%"> Заместитель директора по учебно-методической работе</td>
                <td> БОНДАРЕВА Мария Александровна</td>
                <td> 218А</td>
                <td>326 92 06</td>
                <td>1-й, 3-й понедельник</td>
                <td>14.00 - 19.00</td>
                <td><a href="mailto:director@college-ripo.by">director@college-ripo.by </a></td>
            </tr>
            <tr>
                <td width="25%"> Заместитель директора по административно-хозяйственной работе</td>
                <td>ЖУКОВЕЦ Алексей Николаевич</td>
                <td> 226</td>
                <td>	374 44 03</td>
                <td>2-й, 4-й понедельник</td>
                <td>14.00 - 19.00</td>
                <td><a href="mailto:director@college-ripo.by">director@college-ripo.by </a></td>
            </tr>
            <tr>
                <td width="25%"> Заместитель директора по внебюджетной деятельности</td>
                <td> ЧИЖИК Игорь Васильевич</td>
                <td> 254</td>
                <td> 221 79 04</td>
                <td>1-й, 3-й четверг</td>
                <td>14.00 - 19.00</td>
                <td><a href="mailto:director@college-ripo.by">director@college-ripo.by </a></td>
            </tr>
            <tr>
                <td width="25%"> Начальник отдела бухгалтерского учета и отчетности</td>
                <td>РОМАНОВСКАЯ Людмила Николаевна</td>
                <td>310</td>
                <td>397 68 34</td>
                <td>2-й, 4-й четверг</td>
                <td>14.00 - 19.00</td>
                <td><a href="mailto:director@college-ripo.by">director@college-ripo.by </a></td>
            </tr>
        </tbody>
    </table>
</div>

<div class="table">
    <p class="head1" [innerHTML]="'schedule-kstmia.text2' | translate"></p>
    <table>
        <tbody>
            <tr>
                <td width="25%" class="head1">Должность</td>
                <td width="22%" class="head1">Фамилия Имя Отчество</td>
                <td width="7%" class="head1">Кабинет</td>
                <td width="8%" class="head1">Телефон</td>
                <td width="10%" class="head1">Дни недели</td>
                <td width="9%" class="head1">Время</td>
                <td width="25%" class="head1">Адрес электронной почты</td>
            </tr>
            <tr>
                <td >Секретарь приемной</td>
                <td >ЖУКОВЕЦ Светлана Олеговна</td>
                <td >202</td>
                <td >396 36 25</td>
                <td >понедельник-пятница</td>
                <td >9.00-13.00    
                    <br> 14.00-16.00</td>
                <td ><a href="mailto:director@college-ripo.by">director@college-ripo.by </a></td>
            </tr>
        </tbody>
    </table>
</div>

</div>