import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsComponent } from '../news/news.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { News } from 'src/app/model/news';

@Component({
  selector: 'news-search-component',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NewsComponent,
    MatButtonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
  ],
  templateUrl: './news-search.component.html',
  styleUrls: ['./news-search.component.sass']
})
export class NewsSearchComponent {
  control = new FormControl('');
  news: News[];
  limitedNews: News[];
  streets: string[] = ['учащиеся', 'победили'];
  filteredStreets: Observable<string[]>;
link: any;

  ngOnInit() {
    this.loadTranslations();
    this.filteredStreets = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe(() => {
      this.loadTranslations();
    });
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.streets.filter(street => this._normalizeValue(street).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  loadTranslations() {
      this.translate.get('main-page.news').subscribe((res: any) => {
        this.news = res;
        this.limitedNews = res.slice(0,3)
      });
  }
}
