<div class="side-menu-wrapper">
  <div class="menu-column">
    <!-- openSite для сторонних ссылок -->
    

    <div class="accordion" id="accordionOne">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            {{'main-page.side-menu.top'|translate}}
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionOnetop">
          <div class="accordion-body">
              <div class="accordion-item" (click)="navigateTo('/about-college/mspo')">
                  {{'main-page.side-menu.mech-syst'|translate}}
              </div>
              <div class="accordion-item" (click)="navigateTo('/about-college/omp')">
                  {{'main-page.side-menu.machinery-system'|translate}}
              </div>
              <div class="accordion-item" (click)="navigateTo('/about-college/oi-ra')">
                  {{'main-page.side-menu.car-maintenance'|translate}}
              </div>
              <div class="accordion-item" (click)="navigateTo('/about-college/pto')">
                  {{'main-page.side-menu.prof-tech-edu'|translate}}
              </div>
          </div>
        </div>
      </div>
    </div>

   
    <div class="menu-block" (click)="navigateTo('/about-college/prof-sotr')"> {{'main-page.side-menu.employees-union'|translate}}</div>
    <div class="menu-block" (click)="navigateTo('/about-college/prof-styd')"> {{'main-page.side-menu.studs-union'|translate}}</div>
    <div class="menu-block" (click)="navigateTo('/about-college/brsm')"> {{'main-page.side-menu.brsm'|translate}}</div>
    <div class="menu-block" (click)="navigateTo('/about-college/industrial-training')"> {{'main-page.side-menu.industrial-training'|translate}}</div>
    <div class="menu-block" (click)="navigateTo('/about-college/competencecenter')"> {{'main-page.side-menu.competence'|translate}}</div>
    <div class="menu-block" (click)="navigateTo('/about-college/hostel')"> {{'main-page.side-menu.hostel'|translate}}</div>
    <div class="menu-block" (click)="navigateTo('/about-college/library')"> {{'main-page.side-menu.library'|translate}}</div>
    <div class="menu-block" (click)="openSite('https://profbiblioteka.by/')">{{'main-page.side-menu.el-lib'|translate}}</div>
    
   
    <div class="accordion" id="accordionOne">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            {{'main-page.side-menu.CybercrimePrevention'|translate}}
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#CybercrimePrevention">
          <div class="accordion-body">
              <div class="accordion-item" (click)="openSite('https://disk.yandex.by/d/F03-laudj7830g')">
                  {{'main-page.side-menu.CybercrimePreventionInformation'|translate}}
              </div>
              <div class="accordion-item" (click)="openSite('https://disk.yandex.com/d/37nTesU3MWtwbQ')">
                  {{'main-page.side-menu.Vishing'|translate}}
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
