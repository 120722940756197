import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { News } from 'src/app/model/news';

@Component({
  standalone: true,
  imports:[CommonModule],
  selector: 'news-component',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.sass'],
})
export class NewsComponent {
getSafeHtml(arg0: any) {
throw new Error('Method not implemented.');
}
  @Input('i-news') news: News;
}
